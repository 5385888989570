import { createBrowserRouter } from 'react-router-dom';
import { Checkout } from '@layout/Checkout.jsx';
import AereoReservaCheckout from '@pages/AereoReservaCheckout.jsx';
import AereoReservaConfirmar from '@pages/AereoReservaConfirmar.jsx';
import AereoReservaEtapas from '@pages/AereoReservaEtapas.jsx';
import AereoReservaPagamento from '@pages/AereoReservaPagamento.jsx';
import * as Sentry from '@sentry/react';
import { withAccessControl } from '@system/acl';
import App from './App.jsx';
import { PagePath, pagesACL } from './pages-config.js';
import Admin from './pages/Admin.jsx';
import AdminAeroportos from './pages/AdminAeroportos.jsx';
import AdminAgencias from './pages/AdminAgencias.jsx';
import AdminAssentos from './pages/AdminAssentos.jsx';
import AdminClasses from './pages/AdminClasses.jsx';
import AdminCompanhias from './pages/AdminCompanhias.jsx';
import AdminFabricantes from './pages/AdminFabricantes.jsx';
import AdminRegrasTarifarias from './pages/AdminRegrasTarifarias.jsx';
import AdminServicosBordo from './pages/AdminServicosBordo.jsx';
import AdminUsuarios from './pages/AdminUsuarios.jsx';
import AdminUsuariosNovo from './pages/AdminUsuariosNovo.jsx';
import Aereo from './pages/Aereo.jsx';
import AereoCheckin from './pages/AereoCheckin.jsx';
import AereoCheckinLocalizador from './pages/AereoCheckinLocalizador.jsx';
import AereoFrota from './pages/AereoFrota.jsx';
import AereoFrotaAssentos from './pages/AereoFrotaAssentos.jsx';
import AereoFrotaCadastrar from './pages/AereoFrotaCadastrar.jsx';
import AereoFrotaEditar from './pages/AereoFrotaEditar.jsx';
import AereoFrotaRotas from './pages/AereoFrotaRotas.jsx';
import AereoReservas from './pages/AereoReservas.jsx';
import AereoReservasConfirmar_Legacy from './pages/AereoReservasConfirmar_Legacy.jsx';
import AereoReservasResultados from './pages/AereoReservasResultados.jsx';
import AereoReservasSelecionar_Legacy from './pages/AereoReservasSelecionar_Legacy.jsx';
import AereoSaidas from './pages/AereoSaidas.jsx';
import AereoSaidasCadastrar from './pages/AereoSaidasCadastrar.jsx';
import AereoSaidasEditar from './pages/AereoSaidasEditar.jsx';
import AereoSaidasPrecificar from './pages/AereoSaidasPrecificar.jsx';
import ErrorPage from './pages/ErrorPage.jsx';
import Financeiro from './pages/Financeiro.jsx';
import Home from './pages/Home.jsx';
import Hotel from './pages/Hotel.jsx';
import Login from './pages/Login.jsx';
import MeusDados from './pages/MeusDados.jsx';
import NovaSenha from './pages/NovaSenha.jsx';
import NovoUsuario from './pages/NovoUsuario.jsx';
import PacoteFixo from './pages/PacoteFixo.jsx';
import RecuperarSenha from './pages/RecuperarSenha.jsx';
import Relatorios from './pages/Relatorios.jsx';
import RelatorioVisualizacao from './pages/RelatorioVisualizacao.jsx';
import ReservasExternasPesquisar from './pages/ReservasExternasPesquisar.jsx';
import Rodoviario from './pages/Rodoviario.jsx';
import DetalhesReserva from '@pages/DetalhesReserva.jsx';
import GestaoReservas from '@pages/GestaoReservas.jsx';
import Reservas from '@pages/Reservas.jsx';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: PagePath.RecuperarSenha,
    element: <RecuperarSenha />,
  },
  {
    path: PagePath.NovaSenha,
    element: <NovaSenha />,
  },
  {
    path: PagePath.NovoUsario,
    element: <NovoUsuario />,
  },
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: PagePath.Home,
        element: <Home />,
      },
      {
        path: PagePath.MeusDados,
        element: <MeusDados />,
      },
      {
        path: PagePath.Aereo,
        Component: withAccessControl(<Aereo />, pagesACL[PagePath.Aereo]),
      },
      {
        path: PagePath.AereoFrota,
        Component: withAccessControl(
          <AereoFrota />,
          pagesACL[PagePath.AereoFrota],
        ),
      },
      {
        path: PagePath.AereoFrotaCadastrar,
        Component: withAccessControl(
          <AereoFrotaCadastrar />,
          pagesACL[PagePath.AereoFrotaCadastrar],
        ),
      },
      {
        path: PagePath.AereoFrotaEditar,
        Component: withAccessControl(
          <AereoFrotaEditar />,
          pagesACL[PagePath.AereoFrotaEditar],
        ),
      },
      {
        path: PagePath.AereoFrotaAssentos,
        Component: withAccessControl(
          <AereoFrotaAssentos />,
          pagesACL[PagePath.AereoFrotaAssentos],
        ),
      },
      {
        path: PagePath.AereoFrotaRotas,
        Component: withAccessControl(
          <AereoFrotaRotas />,
          pagesACL[PagePath.AereoFrotaRotas],
        ),
      },
      {
        path: PagePath.AereoSaidas,
        Component: withAccessControl(
          <AereoSaidas />,
          pagesACL[PagePath.AereoSaidas],
        ),
      },
      {
        path: PagePath.AereoSaidasCadastrar,
        Component: withAccessControl(
          <AereoSaidasCadastrar />,
          pagesACL[PagePath.AereoSaidasCadastrar],
        ),
      },
      {
        path: PagePath.AereoSaidasEditar,
        Component: withAccessControl(
          <AereoSaidasEditar />,
          pagesACL[PagePath.AereoSaidasEditar],
        ),
      },
      {
        path: PagePath.AereoSaidasPrecificar,
        Component: withAccessControl(
          <AereoSaidasPrecificar />,
          pagesACL[PagePath.AereoSaidasPrecificar],
        ),
      },
      {
        path: PagePath.AereoReservas,
        Component: withAccessControl(
          <AereoReservas />,
          pagesACL[PagePath.AereoReservas],
        ),
      },
      {
        path: PagePath.AereoReservasResultados,
        Component: withAccessControl(
          <AereoReservasResultados />,
          pagesACL[PagePath.AereoReservas],
        ),
      },
      {
        path: PagePath.AereoReservasSelecionar_Legacy,
        Component: withAccessControl(
          <AereoReservasSelecionar_Legacy />,
          pagesACL[PagePath.AereoReservasSelecionar_Legacy],
        ),
      },
      {
        path: PagePath.AereoReservasConfirmar_Legacy,
        Component: withAccessControl(
          <AereoReservasConfirmar_Legacy />,
          pagesACL[PagePath.AereoReservasConfirmar_Legacy],
        ),
      },
      {
        path: PagePath.AeroCheckin,
        Component: withAccessControl(
          <AereoCheckin />,
          pagesACL[PagePath.AeroCheckin],
        ),
      },
      {
        path: PagePath.AeroCheckinLocalizador,
        Component: withAccessControl(
          <AereoCheckinLocalizador />,
          pagesACL[PagePath.AeroCheckinLocalizador],
        ),
      },
      {
        path: PagePath.Hotel,
        Component: withAccessControl(<Hotel />, pagesACL[PagePath.Hotel]),
      },
      {
        path: PagePath.Rodoviario,
        Component: withAccessControl(
          <Rodoviario />,
          pagesACL[PagePath.Rodoviario],
        ),
      },
      {
        path: PagePath.PacoteFixo,
        Component: withAccessControl(
          <PacoteFixo />,
          pagesACL[PagePath.PacoteFixo],
        ),
      },
      {
        path: PagePath.Admin,
        Component: withAccessControl(<Admin />, pagesACL[PagePath.Admin]),
      },
      {
        path: PagePath.AdminRegrasTarifarias,
        Component: withAccessControl(
          <AdminRegrasTarifarias />,
          pagesACL[PagePath.AdminRegrasTarifarias],
        ),
      },
      {
        path: PagePath.AdminAgencias,
        Component: withAccessControl(
          <AdminAgencias />,
          pagesACL[PagePath.AdminAgencias],
        ),
      },
      {
        path: PagePath.AdminCompanhias,
        Component: withAccessControl(
          <AdminCompanhias />,
          pagesACL[PagePath.AdminCompanhias],
        ),
      },
      {
        path: PagePath.AdminAeroportos,
        Component: withAccessControl(
          <AdminAeroportos />,
          pagesACL[PagePath.AdminAeroportos],
        ),
      },
      {
        path: PagePath.AdminAssentos,
        Component: withAccessControl(
          <AdminAssentos />,
          pagesACL[PagePath.AdminAssentos],
        ),
      },
      {
        path: PagePath.AdminClasses,
        Component: withAccessControl(
          <AdminClasses />,
          pagesACL[PagePath.AdminClasses],
        ),
      },
      {
        path: PagePath.AdminFabricantes,
        Component: withAccessControl(
          <AdminFabricantes />,
          pagesACL[PagePath.AdminFabricantes],
        ),
      },
      {
        path: PagePath.AdminServicosBordo,
        Component: withAccessControl(
          <AdminServicosBordo />,
          pagesACL[PagePath.AdminServicosBordo],
        ),
      },
      {
        path: PagePath.AdminUsuarios,
        Component: withAccessControl(
          <AdminUsuarios />,
          pagesACL[PagePath.AdminUsuarios],
        ),
      },
      {
        path: PagePath.AdminUsuariosNovo,
        Component: withAccessControl(
          <AdminUsuariosNovo />,
          pagesACL[PagePath.AdminUsuariosNovo],
        ),
      },
      {
        path: PagePath.Relatorios,
        Component: withAccessControl(
          <Relatorios />,
          pagesACL[PagePath.Relatorios],
        ),
      },
      {
        path: PagePath.Relatorio,
        Component: withAccessControl(
          <RelatorioVisualizacao />,
          pagesACL[PagePath.Relatorio],
        ),
      },
      {
        path: PagePath.Financeiro,
        Component: withAccessControl(
          <Financeiro />,
          pagesACL[PagePath.Financeiro],
        ),
      },
      {
        path: PagePath.Reservas,
        Component: withAccessControl(<Reservas />, pagesACL[PagePath.Reservas]),
      },
      {
        path: PagePath.ReservasExternasPesquisar,
        Component: withAccessControl(
          <ReservasExternasPesquisar />,
          pagesACL[PagePath.ReservasExternasPesquisar],
        ),
      },
      {
        path: PagePath.ReservasGerenciar,
        Component: withAccessControl(
          <GestaoReservas />,
          pagesACL[PagePath.ReservasGerenciar],
        ),
      },
      {
        path: PagePath.DetalhesReserva,
        Component: withAccessControl(
          <DetalhesReserva />,
          pagesACL[PagePath.DetalhesReserva],
        ),
      },
    ],
  },
  {
    element: <Checkout />,
    path: PagePath.Checkout,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <AereoReservaEtapas />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            path: PagePath.AereoReservasCheckout,
            element: <AereoReservaCheckout />,
            errorElement: <ErrorPage />,
          },
          {
            path: PagePath.AereoReservasPagamento,
            element: <AereoReservaPagamento />,
            errorElement: <ErrorPage />,
          },
          {
            path: PagePath.AereoReservasConfirmar,
            element: <AereoReservaConfirmar />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
]);
