import axios from 'axios';
import { refreshToken } from '@stores/authentication';

const api = axios.create({
  baseURL: import.meta.env.VITE_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

const REFRESH_TOKEN_URL = '/authentication/refresh-token';

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = error?.response?.status || error?.statusCode || '';
    if (error?.code === 'ERR_NETWORK') {
      return Promise.reject('Não foi possivel conectar ao servidor');
    } else if (status === 401 && originalRequest.url !== REFRESH_TOKEN_URL) {
      try {
        const newAccessToken = await refreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

        return api(originalRequest);
      } catch (refreshError) {
        window.location.href = '/';
        return;
      }
    }

    if (error?.response?.data) throw error.response.data;
    else throw error;
  },
);

export default api;
